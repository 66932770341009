import React from 'react';

const ContentTab3 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(2)} data-index="1">
      <h4 className="tab-content--title">Segmentação</h4>

      <div className="tab-content--description">
        <p>Toda a plataforma é segmentada por tags (#) possibilitando conteúdos direcionados para grupos de pessoas. Só visualiza a informação quem realmente precisa dela. As tags são criadas de acordo com a sua necessidade, podendo ser por região, filial, squads, projetos, setor, cargo, time, etc.</p>
      </div>
    </div>
  )
}

export default ContentTab3;