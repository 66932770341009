import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-yellow.png';
import image1 from '../../../assets/images/slider/slide-5-image-1.svg';
import image2 from '../../../assets/images/slider/slide-5-image-2.svg';

const CircleTab5 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(4)} data-index="2">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="iphone">
        <img src={image1} height="500px" alt="Imagem - Noz" />
      </div>

      <div className="image image-1">
        <img src={image2} height="320px" alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab5;