import React, { useState } from 'react';
import axios from 'axios';

import { useAppear, useSplit } from '../../utils/general';
import { line3, line4 } from '../../assets/icons/lines';

const SectionContact = () => {
  useSplit('.section-contact .content-title');
  useAppear('.section-contact', 400);

  const [contact, setContact] = useState({
    name: '',
    email:'',
    company:'',
    phone:'',
    office: '',
    nContributors: '',
    message: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [answer, setAnswer] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(`https://57pia8lrb1.execute-api.us-east-1.amazonaws.com/dev/ombudsman`, {
      name: contact.name,
      subject: "Contato recebido na LP Noz",
      email: contact.email,
      company: "",
      phone: contact.phone,
      office: "",
      nContributors: contact.nContributors,
      text: contact.message,
    })
    .then(() => {
      setAnswer("Sua mensagem foi enviada com sucesso!");
    })
    .catch((error) => {
      setAnswer("Ocorreu algum erro ao tentar enviar sua mensagem. Por favor, tente novamente!");
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <section id="contato" className="section section-contact">
      <div className="line-1">
        {line3}
      </div>

      <div className="line-2">
        {line3}
      </div>

      <div className="line-3">
        {line4}
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title">Vamos conversar?</h2>
          </div>

          <form className="form" onSubmit={e => handleSubmit(e)}>
            <div className="form-row form-flex">
              <div className="field-group">
                <div className="label">Nome</div>
                <input
                 type="text"
                 className="field"
                 onChange={e => setContact({...contact, name: e.target.value})}
                 required
                />
              </div>

              <div className="field-group">
                <div className="label">E-mail</div>
                <input
                 type="email"
                 className="field"
                 onChange={e => setContact({...contact, email: e.target.value})}
                 required
                />
              </div>

              <div className="field-group">
                <div className="label">Telefone</div>
                <input
                 type="tel"
                 className="field"
                 onChange={e => setContact({...contact, phone: e.target.value})}
                />
              </div>
              
              <div className="field-group">
                <div className="label">Nº de usuários</div>
                <input
                 type="text"
                 className="field"
                 onChange={e => setContact({...contact, nContributors: e.target.value})}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="field-group">
                <div className="label">Mensagem</div>
                <textarea
                 className="field"
                 onChange={e => setContact({...contact, message: e.target.value})}
                 required
                ></textarea>
              </div>
            </div>

            <div className="form-row">
              <p>{answer}</p>
            </div>

            <div className="form-submit">
              <button type="submit" title="Enviar" aria-label="Enviar">
                { isLoading ? 
                  <span className="is-loading"></span>
                :
                  <span>Enviar</span>
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default SectionContact;