import React from 'react';

import { line5 } from '../../../assets/icons/lines';
import imageBubble from '../../../assets/images/slider/bubble-yellow.png';
import image1 from '../../../assets/images/slider/slide-3-image-1.png';
import image2 from '../../../assets/images/slider/slide-3-image-2.png';
import image3 from '../../../assets/images/slider/slide-3-image-3.png';

const CircleTab3 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(2)} data-index="1">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>
      <div className="line">
        {line5}
      </div>

      <div className="image image-1">
        <img src={image1} alt="Imagem - Noz" />
      </div>

      <div className="image image-2">
        <img src={image2} alt="Imagem - Noz" />
      </div>

      <div className="image image-3">
        <img src={image3} alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab3;