import React from 'react';

export const circle = (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g transform="translate(-628.000000, -1943.000000)" stroke="#501F99">
          <circle cx="643" cy="1958" r="14"></circle>
        </g>
      </g>
  </svg>
)