import React, { useState, useEffect } from 'react';
import { useAppear, useSplit } from '../../utils/general';

import CircleTab1 from './CircleTabs/CircleTab1';
import CircleTab2 from './CircleTabs/CircleTab2';
import CircleTab3 from './CircleTabs/CircleTab3';
import CircleTab4 from './CircleTabs/CircleTab4';
import CircleTab5 from './CircleTabs/CircleTab5';
import CircleTab6 from './CircleTabs/CircleTab6';
import CircleTab7 from './CircleTabs/CircleTab7';
import CircleTab8 from './CircleTabs/CircleTab8';

import ContentTab1 from './ContentTabs/ContentTab1';
import ContentTab2 from './ContentTabs/ContentTab2';
import ContentTab3 from './ContentTabs/ContentTab3';
import ContentTab4 from './ContentTabs/ContentTab4';
import ContentTab5 from './ContentTabs/ContentTab5';
import ContentTab6 from './ContentTabs/ContentTab6';
import ContentTab7 from './ContentTabs/ContentTab7';
import ContentTab8 from './ContentTabs/ContentTab8';

import imageCircle from '../../assets/images/slide-circle.png';
import { circle } from '../../assets/icons/circle';
import iconArrows1 from '../../assets/icons/slide-arrows-1.svg';
import iconArrows2 from '../../assets/icons/slide-arrows-2.svg';

const SectionSlide = () => {
  useSplit('.section-slide .tab-content .tab-content--title');
  useAppear('.section-slide .flex-wrapper', 300);

  const lastIndex = 7;
  const [index, setIndex] = useState();
  const listDots = new Array(8).fill();

  const isCurrentTab = (number) => index === number ? 'tab-content active' : 'tab-content';

  const slideArrow = (number) => {
    if (number === -1) {
      setIndex(lastIndex);
    } else {
      setIndex(number <= lastIndex ? number : 0);
    }

    setTimeout(() => changeTabsHeigth(), 100);
  }

  const changeTabsHeigth = () => {
    const tabs = document.querySelector('.right .tabs');
    const tab = tabs.querySelector('.tab-content.active');
    const tabHeight = tab.offsetHeight;

    tabs.style.height = tabHeight + 'px';
  }

  useEffect(() => {
    const element = document.querySelector('.section-slide .flex-wrapper');

    element.addEventListener('appeared', () => setIndex(0));

    const changeSlider = () => {
      if (index === null) return;
      setIndex((index + 1) <= lastIndex ? (index + 1) : 0);
      changeTabsHeigth();
    }

    const slideInterval = setTimeout(changeSlider, 7000);
    return () => clearTimeout(slideInterval);
  }, [index]);

  return (
    <section id="como-funciona" className="section section-slide">
      <div className="container">
        <div className="flex-wrapper">
          <div className="circle">
            <div className="circle-image" data-index={index}>
              <img src={imageCircle} alt="Imagem - Noz" />
            </div>

            <div className="circle-dots">
              {listDots.map((number, position) => {
                return (
                  <span key={position} data-index={position} className={index === position ? 'active' : ''}>{circle}</span>
                )
              })}
            </div>

            <div className="circle-tabs">
              <CircleTab1 isCurrentTab={isCurrentTab} />
              <CircleTab2 isCurrentTab={isCurrentTab} />
              <CircleTab3 isCurrentTab={isCurrentTab} />
              <CircleTab4 isCurrentTab={isCurrentTab} />
              <CircleTab5 isCurrentTab={isCurrentTab} />
              <CircleTab6 isCurrentTab={isCurrentTab} />
              <CircleTab7 isCurrentTab={isCurrentTab} />
              <CircleTab8 isCurrentTab={isCurrentTab} />
            </div>
          </div>

          <div className="right">
            <div className="tabs">
              <ContentTab1 isCurrentTab={isCurrentTab} />
              <ContentTab2 isCurrentTab={isCurrentTab} />
              <ContentTab3 isCurrentTab={isCurrentTab} />
              <ContentTab4 isCurrentTab={isCurrentTab} />
              <ContentTab5 isCurrentTab={isCurrentTab} />
              <ContentTab6 isCurrentTab={isCurrentTab} />
              <ContentTab7 isCurrentTab={isCurrentTab} />
              <ContentTab8 isCurrentTab={isCurrentTab} />
            </div>

            <div className="navigation">
              <button
                type="button"
                title="Voltar slide"
                aria-label="Voltar slide"
                onClick={() => slideArrow(index - 1)}>
                <img src={iconArrows1} alt="Ícone - Noz" />
              </button>

              <div className="count">
                <span>{index + 1}</span>
                <span>/</span>
                <span>8</span>
              </div>

              <button
                type="button"
                title="Avançar slide"
                aria-label="Avançar slide"
                onClick={() => slideArrow(index + 1)}>
                <img src={iconArrows2} alt="Ícone - Noz" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSlide;