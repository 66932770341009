import React, { useEffect } from 'react';

import Button from '../Button';

import logo from '../../assets/images/noz-logo.png';

const Header = ({ visible = false }) => {
  const scrollListener = () => {
    const element = document.querySelector('#main-header');
    const scroll = window.pageYOffset;

    if (scroll >= 50) {
      if (!element.classList.contains('sticky')) {
        element.classList.add('sticky');
      }
    } else {
      if (element.classList.contains('sticky')) {
        element.classList.remove('sticky');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <header id="main-header" className={visible ? 'single sticky' : ''}>
      <div className="container">
        <div className="flex-wrapper">
          <div className="logo">
            <div className="logo-icon">
              <img src={logo} alt="Imagem - NOZ" />
            </div>
          </div>

          <div className="navigation">
            <ul>
              <li>
                <a
                  href="#inicio"
                  type="button"
                  title="Ir para a seção Início"
                  aria-label="Ir para a seção Início">
                  Início
                </a>
              </li>

              <li>
                <a
                  href="#como-funciona"
                  type="button"
                  title="Ir para a seção Como funciona"
                  aria-label="Ir para a seção Como funciona">
                  Como funciona
                </a>
              </li>

              <li className="cta">
                <Button />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;