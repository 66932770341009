import React from 'react';

import { useAppear, useSplit } from '../../utils/general';

const SectionAbout = () => {
  useSplit('.section-about .content-title');
  useAppear('.section-about .flex-wrapper', 200);

  return (
    <section className="section section-about">
      <div className="container">
        <div className="flex-wrapper">
        <div className="content">
            <h2 className="content-title">
              A menor distância entre pessoas é uma comunicação assertiva.
            </h2>
            <h2 className="content-title">
              E para aproximá-las, existe o NOZ.
            </h2>
            <div className="content-description">
              <p>O NOZ é uma plataforma que oferece uma experiência de comunicação <b>personalizada</b>, de acordo com a <b>segmentação</b> do seu público e de maneira <b>gamificada</b>.</p>
            </div>
          </div>

          <div className="video">
            <iframe src="https://player.vimeo.com/video/575946220?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="NOZ - Plataforma de Comunica&amp;ccedil;&amp;atilde;o.mp4"></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAbout;