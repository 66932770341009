import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-blue.png';
import image1 from '../../../assets/images/slider/slide-1-image-1.png';
import image2 from '../../../assets/images/slider/slide-1-image-2.png';
import image3 from '../../../assets/images/slider/slide-1-image-3.png';
import image4 from '../../../assets/images/slider/slide-1-image-4.png';
import image5 from '../../../assets/images/slider/slide-1-image-5.png';

const CircleTab1 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(0)} data-index="6">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="image image-1">
        <img src={image1} alt="Imagem - Noz" />
      </div>

      <div className="image image-2">
        <img src={image2} alt="Imagem - Noz" />
      </div>

      <div className="image image-3">
        <img src={image3} alt="Imagem - Noz" />
      </div>

      <div className="image image-4">
        <img src={image4} alt="Imagem - Noz" />
      </div>

      <div className="image image-5">
        <img src={image5} alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab1;