import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-yellow.png';
import image1 from '../../../assets/images/slider/slide-7-image-1.svg';
import image2 from '../../../assets/images/slider/slide-7-image-2.svg';
import image3 from '../../../assets/images/slider/slide-7-image-3.svg';

const CircleTab7 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(6)} data-index="4">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="image image-1">
        <img src={image1} height="500px" alt="Imagem - Noz" />
      </div>

      <div className="image image-2">
        <img src={image2} width="250px" alt="Imagem - Noz" />
      </div>

      <div className="image image-3">
        <img src={image3} width="250px" alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab7;