import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-blue.png';
import image1 from '../../../assets/images/slider/slide-4-image-1.png';
import image2 from '../../../assets/images/slider/slide-4-image-2.png';

const CircleTab4 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(3)} data-index="0">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="iphone">
        <img src={image1} alt="Imagem - Noz" />
      </div>

      <div className="nuts">
        <img src={image2} alt="Imagem - Noz" />
      </div> 
    </div>
  )
}

export default CircleTab4;