import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-yellow.png';
import image1 from '../../../assets/images/slider/slide-8-image-1.svg';

const CircleTab8 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(7)} data-index="7">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="macbook">
        <img src={image1} width="500px" alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab8;