import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-blue.png';
import image1 from '../../../assets/images/slider/slide-6-image-1.svg';
import image2 from '../../../assets/images/slider/slide-6-image-2.svg';
import image3 from '../../../assets/images/slider/slide-6-image-3.svg';
import image4 from '../../../assets/images/slider/slide-6-image-4.svg';
import image5 from '../../../assets/images/slider/slide-6-image-5.svg';

const CircleTab6 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(5)} data-index="3">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="image image-1">
        <img src={image1} width="250px" alt="Imagem - Noz" />
      </div>

      <div className="image image-2">
        <img src={image2} width="230px" alt="Imagem - Noz" />
      </div>

      <div className="image image-3">
        <img src={image3} width="100px" alt="Imagem - Noz" />
      </div>

      <div className="image image-4">
        <img src={image4} width="95px" alt="Imagem - Noz" />
      </div>

      <div className="image image-5">
        <img src={image5} width="110px" alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab6;