import React from 'react';

const ContentTab2 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(1)} data-index="5">
      <h4 className="tab-content--title">Personalização</h4>

      <div className="tab-content--description">
        <p>Para aproximar a sua audiência e criar familiaridade, você pode personalizar o NOZ de acordo com a sua identidade visual; do seu jeito, com a sua marca e suas cores. Você também pode nomear os itens do menu como quiser.</p>
      </div>
    </div>
  )
}

export default ContentTab2;