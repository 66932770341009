import React from 'react';

import iconNuts from '../../../assets/icons/slide-nuts.svg';
import iconBag from '../../../assets/icons/slide-bag.svg';
import iconHeart from '../../../assets/icons/slide-heart.svg';
import iconArrowRight from '../../../assets/icons/slide-arrow-right.svg';
import iconEqual from '../../../assets/icons/slide-equal.svg';

const ContentTab4 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(3)} data-index="0">
      <h4 className="tab-content--title">Gamificação</h4>

      <div className="tab-content--description">
        <p>Toda interação na plataforma e ações offline podem gerar pontos e com eles os usuários resgatam os produtos disponibilizados na loja, incentivando assim, o engajamento através da gamificação. O administrador poderá informar quantos pontos vale cada ação e poderá pontuar usuários ou grupos manualmente através do painel administrativo.</p>
      </div>

      <div className="tab-content--flex">
        <div className="item">
          <div className="item-icon">
            <img src={iconNuts} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Acumule pontos</h4>
        </div>

        <div className="separator">
          <img src={iconArrowRight} alt="Ícone - Noz" />
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconBag} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Troque na loja</h4>
        </div>

        <div className="separator">
          <img src={iconEqual} alt="Ícone - Noz" />
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconHeart} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Mais engajamento</h4>
        </div>
      </div>
    </div>
  )
}

export default ContentTab4;