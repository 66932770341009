import React from 'react';

export const line1 = (
  <svg width="350px" height="322px" viewBox="0 0 350 322" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
      <g transform="translate(0.000000, -348.000000)" stroke="#FFFFFF">
        <path d="M-58.5477473,621.961169 C-126.99525,684.006565 -360.324346,632.590976 -267.463328,799.888783 C-174.60231,967.186589 -283.415393,1111.74105 -210.373371,1160.72138 C-137.331349,1209.70171 -14.8794026,952.804872 129.939956,1055.76353 C274.759315,1158.72218 221.832362,960.688334 319.825852,919.713545 C385.154845,892.397018 420.358235,807.503709 425.436023,665.033616 C450.095441,622.997568 421.500962,583.936464 339.652585,547.850305 C257.804209,511.764146 117.775041,476.480711 -80.43492,442 C-20.2118034,520.610515 -12.9160792,580.597571 -58.5477473,621.961169 Z" transform="translate(72.500000, 804.500000) scale(-1, 1) rotate(140.000000) translate(-72.500000, -804.500000)"></path>
      </g>
    </g>
  </svg>
);

export const line2 = (
  <svg width="605px" height="591px" viewBox="0 0 605 591" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
      <g transform="translate(-761.000000, 0.000000)" stroke="#FFFFFF">
        <path d="M992.452253,44.961169 C924.00475,107.006565 690.675654,55.5909759 783.536672,222.888783 C876.39769,390.186589 767.584607,534.741047 840.626629,583.721377 C913.668651,632.701708 1036.1206,375.804872 1180.93996,478.763526 C1325.75931,581.72218 1272.83236,383.688334 1370.82585,342.713545 C1436.15484,315.397018 1471.35823,230.503709 1476.43602,88.0336164 C1501.09544,45.9975682 1472.50096,6.93646446 1390.65259,-29.1496947 C1308.80421,-65.2358538 1168.77504,-100.519289 970.56508,-135 C1030.7882,-56.3894849 1038.08392,3.59757142 992.452253,44.961169 Z"></path>
      </g>
    </g>
  </svg>
);

export const line3 = (
  <svg width="515px" height="247px" viewBox="0 0 515 247" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeOpacity="0.5">
      <g transform="translate(-248.000000, -3391.000000)" stroke="#FFFFFF" strokeWidth="1.824">
        <path d="M222.504802,3697.14123 C232.571274,3671.97943 302.5927,3683.82862 315.403541,3630.69202 C328.214382,3577.55542 254.555434,3537.14319 269.10247,3503.86416 C283.649506,3470.58512 325.126669,3503.49726 380.755557,3481.18718 C436.384444,3458.8771 459.390704,3372.70376 555.570178,3406.94373 C651.749652,3441.18371 559.280552,3659.74399 687.126968,3644.98664 C772.357913,3635.1484 787.781821,3675.66566 733.398693,3766.53841 C664.331802,3875.56662 583.331874,3932.85312 490.398908,3938.39792 C384.873977,3949.65023 177.356776,3800.72738 222.504802,3697.14123 Z" transform="translate(490.674049, 3669.000000) scale(-1, 1) rotate(47.000000) translate(-490.674049, -3669.000000)"></path>
      </g>
    </g>
  </svg>
);

export const line4 = (
  <svg width="319px" height="400px" viewBox="0 0 319 400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeOpacity="0.5">
      <g transform="translate(-1047.000000, -3238.000000)" stroke="#FFFFFF" strokeWidth="1.824">
        <path d="M1037.5048,3525.14123 C1047.57127,3499.97943 1117.5927,3511.82862 1130.40354,3458.69202 C1143.21438,3405.55542 1069.55543,3365.14319 1084.10247,3331.86416 C1098.64951,3298.58512 1140.12667,3331.49726 1195.75556,3309.18718 C1251.38444,3286.8771 1274.3907,3200.70376 1370.57018,3234.94373 C1466.74965,3269.18371 1374.28055,3487.74399 1502.12697,3472.98664 C1587.35791,3463.1484 1602.78182,3503.66566 1548.39869,3594.53841 C1479.3318,3703.56662 1398.33187,3760.85312 1305.39891,3766.39792 C1199.87398,3777.65023 992.356776,3628.72738 1037.5048,3525.14123 Z" transform="translate(1305.674049, 3497.000000) scale(-1, 1) rotate(-32.000000) translate(-1305.674049, -3497.000000)"></path>
      </g>
    </g>
  </svg>
);

export const line5 = (
  <svg width="104px" height="163px" viewBox="0 0 104 163" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-102.000000, -2062.000000)" fill="#2e63f7" fillRule="nonzero">
        <path d="M103.68455,2062.95119 L104.679774,2063.04881 C96.9554411,2141.79703 127.147919,2193.24948 195.314696,2217.54002 L196.919386,2218.10204 C197.870161,2216.05124 200.23105,2214.9988 202.425591,2215.73178 C204.782863,2216.51911 206.055551,2219.06832 205.268219,2221.42559 C204.480887,2223.78286 201.931682,2225.05555 199.574409,2224.26822 C197.375279,2223.53371 196.120092,2221.26579 196.600842,2219.05084 L197.066338,2219.21328 C126.985248,2195.07635 95.8382358,2142.94299 103.68455,2062.95119 Z"></path>
      </g>
    </g>
  </svg>
);

export const line6 = (
  <svg width="574px" height="528px" viewBox="0 0 574 528" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient x1="0%" y1="83.7651735%" x2="100%" y2="16.2348265%" id="filter-line-5">
        <stop stopColor="#501F99" offset="0%"></stop>
        <stop stopColor="#B3247C" offset="100%"></stop>
      </linearGradient>
    </defs>

    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, -1828.000000)" stroke="url(#filter-line-5)" strokeWidth="1.1">
        <g transform="translate(-270.000000, 1551.000000)">
          <g transform="translate(530.828797, 519.923730) rotate(25.000000) translate(-530.828797, -519.923730) translate(125.328797, 135.423730)">
            <path d="M122.53383,141.514462 C206.761498,113.720259 241.961498,119.570255 228.13383,159.064448 C207.392329,218.305739 58.8341503,446.242072 197.33383,450.628267 C335.83351,455.014462 787.898807,172.992398 714.479924,376.108313 C665.534002,511.518923 506.048325,597.220973 236.022893,633.214462 C163.003854,643.124004 113.799726,646.968141 88.4105111,644.746872" transform="translate(405.448435, 384.825196) rotate(-30.000000) translate(-405.448435, -384.825196) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const line7 = (
  <svg width="596px" height="422px" viewBox="0 0 596 422" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="0%" y1="64.8454133%" x2="100%" y2="35.1545867%" id="filter-line-6">
            <stop stopColor="#501F99" offset="0%"></stop>
            <stop stopColor="#B3247C" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-30.000000, -1930.000000)" stroke="url(#filter-line-6)" strokeWidth="1.0944">
        <g transform="translate(-40.000000, 1790.000000)">
          <path d="M37.627461,468.67365 C65.9099615,458.019461 111.921928,453.444903 122.679384,408.818194 C137.338347,348.006304 53.0531906,301.756762 69.6988151,263.670752 C86.3444396,225.584742 133.805194,263.25086 197.459232,237.718214 C261.11327,212.185569 287.438467,113.564967 397.493012,152.750713 C507.547557,191.936458 401.738653,442.06656 548.028487,425.17759 C596.23632,419.612059 624.927101,428.127447 634.100828,450.723754" transform="translate(335.864145, 306.166600) rotate(-20.000000) translate(-335.864145, -306.166600) "></path>
        </g>
      </g>
    </g>
  </svg>
);