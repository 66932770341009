import React from 'react';

import Header from '../../components/Header/';
import SectionHero from '../../components/SectionHero/';
import SectionAbout from '../../components/SectionAbout/';
import SectionPlatform from '../../components/SectionPlatform/';
import SectionSlide from '../../components/SectionSlide/';
import SectionManagement from '../../components/SectionManagement/';
import SectionContact from '../../components/SectionContact/';
import Footer from '../../components/Footer/';

const Home = () => {
  return (
    <>
      <Header />
      
      <main id="page-home">
        <h1 class="page-title">Noz - plataforma de comunicação interna</h1>
        
        <SectionHero />
        <SectionAbout />
        <SectionPlatform />
        <SectionSlide />
        <SectionManagement />
        <SectionContact />
      </main>
      
      <Footer />
    </>
  )
}

export default Home;