import React from 'react';

const ContentTab5 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(4)} data-index="2">
      <h4 className="tab-content--title">Cursos</h4>

      <div className="tab-content--description">
        <p>Promova treinamentos e capacitações através dos cursos, que são organizados por módulos e possibilitam a inserção de imagens, vídeos, textos e questionários.  Emita certificados e tenha relatórios completos sobre a taxa de aproveitamento.</p>
      </div>
    </div>
  )
}

export default ContentTab5;