import React from 'react';

import { useAppear, useSplit } from '../../utils/general';

import background from '../../assets/images/background.png';
import inter from '../../assets/images//clients/inter-logo.png';
import mrn from '../../assets/images//clients/mrn-logo.png';
import pardini from '../../assets/images//clients/pardini-logo.png';
import suvinil from '../../assets/images//clients/suvinil-logo.png';
import ioasys from '../../assets/images//clients/ioasys-logo.png';
import petrorio from '../../assets/images//clients/petrorio-logo.png';
import semear from '../../assets/images//clients/semear-logo.png';
import hubsocial from '../../assets/images//clients/hubsocial-logo.png';
import dito from '../../assets/images//clients/dito-logo.png';
import brius from '../../assets/images//clients/brius-logo.png';

const SectionPlatform = () => {
  useSplit('.section-platform .wrapper-title');
  useSplit('.section-platform .wrapper-number');
  useAppear('.section-platform .wrapper', 200);

  return (
    <section className="section section-platform">
      <div className="background">
        <img src={background} alt="Imagem - Noz" />
      </div>

      <div className="container">
        <div className="wrapper">
          <div className="wrapper-text">
            <h2 className="wrapper-number">+50 mil</h2>
            <h2 className="wrapper-title">usuários ativos na plataforma</h2>
          </div>
          
          <div className="wrapper-partners">
            <div className="partner">
              <img src={inter} alt="Imagem - Noz" width="100px" />
            </div>

            <div className="partner">
              <img src={mrn} alt="Imagem - Noz" width="80px"/>
            </div>

            <div className="partner">
              <img src={pardini} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={suvinil} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={ioasys} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={petrorio} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={semear} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={hubsocial} alt="Imagem - Noz" width="70px"/>
            </div>

            <div className="partner">
              <img src={dito} alt="Imagem - Noz" width="120px"/>
            </div>

            <div className="partner">
              <img src={brius} alt="Imagem - Noz" width="120px"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionPlatform;