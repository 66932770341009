import React from 'react';

const ContentTab6 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(5)} data-index="3">
      <h4 className="tab-content--title">Arquivos e Galeria</h4>

      <div className="tab-content--description">
        <p>Crie pastas com diversos tipos de arquivos e galeria de fotos e compartilhe de forma segmentada.</p>
      </div>
    </div>
  )
}

export default ContentTab6;