import React from 'react';

const ContentTab7 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(6)} data-index="4">
      <h4 className="tab-content--title">Eventos</h4>

      <div className="tab-content--description">
        <p>Através de um calendário, o usuário visualiza os eventos cadastrados pelo administrador, feriados nacionais, aniversários dos membros do time. Assim que o administrador cadastra um novo usuário na plataforma a data de aniversário será apresentada automaticamente no calendário.</p>
      </div>
    </div>
  )
}

export default ContentTab7;