import { useEffect } from 'react';

export const useAppear = (selector, number) => {
  const { innerHeight } = window;
  const distance = Number(number);
  let appear = false;

  const scrollListener = () => {
    if (appear) return;

    const element = document.querySelector(selector);
    const scroll = window.pageYOffset;
    const offset = element.getBoundingClientRect().top + scroll;

    if ((scroll + innerHeight) > (offset + distance)) {
      element.classList.add('appear');
      element.dispatchEvent(new Event('appeared'));
      appear = true;
    }
  }

  useEffect(() => {
    scrollListener();
    window.addEventListener('scroll', scrollListener);
    window.onload = scrollListener;
  }, []);
}

export const useSplit = (selector) => {
  useEffect(() => {
    const elements = document.querySelectorAll(selector);

    for (let i = 0; i < elements.length; i++) {
      const splitElement = elements[i];
      const text = splitElement.textContent;
      splitElement.innerHTML = '';
      const split = text.split(' ');

      for (let x = 0; x < split.length; x++) {
        let line = split[x];
        let words = line.split(' ');

        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          let letters = word.split('');

          if (word.length >= 1) {
            let wordElement = document.createElement('div');
            wordElement.className = 'word';

            for (let y = 0; y < letters.length; y++) {
              let letter = letters[y];
              let letterElement = document.createElement('s');
              letterElement.textContent = letter;
              wordElement.append(letterElement);
            }

            splitElement.append(wordElement);
          }
        }
      }

      const allWords = splitElement.querySelectorAll('.word');
      const allLetters = splitElement.querySelectorAll('s');

      for (let i = 0; i < allWords.length; i++) {
        allWords[i].setAttribute('data-index', i);
      }
      
      for (let i = 0; i < allLetters.length; i++) {
        allLetters[i].setAttribute('data-index', i);
      }
    }
  }, []);
}