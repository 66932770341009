import React from 'react';

const ContentTab8 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(7)} data-index="6">
      <h4 className="tab-content--title">Versão web</h4>

      <div className="tab-content--description">
        <p>O aplicativo disponível para iOS a Android possui uma versão desktop para que os usuários visualizem e interajam através do computador. O site é responsivo possibilitando o acesso também pelo navegador do celular, o usuário tem acesso a qualquer hora, em qualquer dispositivo.</p>
      </div>
    </div>
  )
}

export default ContentTab8;