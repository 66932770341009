import React from 'react';

import imageBubble from '../../../assets/images/slider/bubble-blue.png';
import image1 from '../../../assets/images/slider/slide-2-image-1.png';
import image2 from '../../../assets/images/slider/slide-2-image-2.png';

const CircleTab2 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(1)} data-index="5">
      <div className="bubble">
        <img src={imageBubble} alt="Imagem - Noz" />
      </div>

      <div className="image image-1">
        <img src={image1} alt="Imagem - Noz" />
      </div>

      <div className="image image-2">
        <img src={image2} alt="Imagem - Noz" />
      </div>
    </div>
  )
}

export default CircleTab2;