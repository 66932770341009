import React from 'react';

import { useAppear, useSplit } from '../../utils/general';
import Button from '../Button';

import circle from '../../assets/images/hero/circle.png';
import mockup from '../../assets/images/hero/mockup.png';
import nozOne from '../../assets/images/hero/noz-1.png';
import nozTwo from '../../assets/images/hero/noz-2.png';
import nozThree from '../../assets/images/hero/noz-3.png';
import logo from '../../assets/images/noz-logo.png';
import { line1, line2 } from '../../assets/icons/lines';

const SectionHero = () => {
  useSplit('.section-hero .wrapper .wrapper-title');
  useAppear('.section-hero', 0);

  return (
    <section id="inicio" className="section section-hero">
      <div className="images">
        <div className="image">
          <img className="image-circle" src={circle} alt="Imagem - NOZ" />
        </div>
        <div className="image-noz image-noz-one">
          <div className="image">
            <img src={nozOne} alt="Imagem - NOZ" />
          </div>
        </div>
        <div className="image-mockup">
          <div className="image">
            <img src={mockup} alt="Imagem - NOZ" />
          </div>
        </div>
        <div className="image-noz image-noz-two">
          <div className="image">
            <img src={nozTwo} alt="Imagem - NOZ" />
          </div>
        </div>
        <div className="image-noz image-noz-three">
          <div className="image">
            <img src={nozThree} alt="Imagem - NOZ" />
          </div>
        </div>
      </div>

      <div className="line-1">
        {line1}
      </div>

      <div className="line-2">
        {line2}
      </div>
      
      <div className="wrapper">
        <div className="wrapper-logo">
          <img src={logo} alt="Imagem - NOZ" />
        </div>

        <h2 className="wrapper-title wrapper-title-comunication">
          A comunicação do seu jeito.
        </h2>
        <h2 className="wrapper-title wrapper-title-noz">
          Para todos, NOZ
        </h2>

        <div className="wrapper-cta">
          <Button />
        </div>
      </div>
    </section>
  )
}

export default SectionHero;