import React from 'react';

import iconCard1 from '../../../assets/icons/slide-card-1.svg';
import iconCard2 from '../../../assets/icons/slide-card-2.svg';
import iconCard3 from '../../../assets/icons/slide-card-3.svg';
import iconCard4 from '../../../assets/icons/slide-card-4.svg';
import iconCard5 from '../../../assets/icons/slide-card-5.svg';

const ContentTab1 = ({ isCurrentTab }) => {
  return (
    <div className={isCurrentTab(0)} data-index="6">
      <h4 className="tab-content--title">Interação</h4>

      <div className="tab-content--description">
        <p>Além dos vários formatos de conteúdo, como fotos, carrossel, vídeos, texto e links, também é possível publicar sorteios, enquetes e formulários. Todas as publicações podem ser agendadas pelo administrador. Os usuários podem curtir, comentar e marcar outras pessoas nas publicações, promovendo a interação e fortalecendo o relacionamento com o seu time.</p>
      </div>

      <div className="tab-content--flex">
        <div className="item">
          <div className="item-icon">
            <img src={iconCard1} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Texto</h4>
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconCard2} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Foto</h4>
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconCard3} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Video</h4>
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconCard4} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Carrossel</h4>
        </div>

        <div className="item">
          <div className="item-icon">
            <img src={iconCard5} alt="Ícone - Noz" />
          </div>

          <h4 className="item-title">Link</h4>
        </div>
      </div>
    </div>
  )
}

export default ContentTab1;