import React from 'react';

import { useAppear, useSplit } from '../../utils/general';
import Button from '../Button';

import painel from '../../assets/images/management/painel.png';

const SectionManagement = () => {
  useSplit('.section-management .content-title');
  useAppear('.section-management .flex-wrapper', 200);

  return (
    <section className="section section-management">
      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title">Gestão completa</h2>

            <div className="content-description">
              <p>Além de fazer toda a gestão dos usuários e conteúdos no painel administrativo, o administrador tem acesso a uma Dashboard contendo todas as métricas sobre engajamento e interações para visualizar o resultado das suas ações e o desempenho das publicações de forma simples, rápida e intuitiva.</p>
            </div>

            <div className="content-cta">
              <Button />
            </div>
          </div>

          <div className="right">
            <div className="right-image-1">
              <img src={painel} alt="Imagem - Noz" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionManagement;