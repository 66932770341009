import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header/';
import Footer from '../../components/Footer/';

const ErrorPage = () => {
  return (
    <>
      <Header visible={true}/>
      
      <main id="page-404">
        <section className="section section-error">
          <div className="container">
            <div className="wrapper">
              <h1 className="wrapper-title">Página não encontrada</h1>

              <div className="wrapper-description">
                <p>Você acessou uma página que não existe.<br/>Clique no botão abaixo para voltar ao início.</p>
              </div>

              <div className="wrapper-cta">
                <Link
                  to="/"
                  className="button"
                  title="Ir para a página principal"
                  aria-label="Ir para a página principal"
                >
                  Voltar
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <Footer />
    </>
  )
}

export default ErrorPage;