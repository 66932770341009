import React from 'react';

import instagram from '../../assets/images/noz-instagram.png'
import facebook from '../../assets/images/noz-facebook.png'
import linkedin from '../../assets/images/noz-linkedin.png'

const Footer = () => {
  return (
    <footer id="main-footer">
      <div className="container">
        <div className="flex-wrapper">
          <p>ACOMPANHEM-NOZ</p>
          <div className="logo">
            <a
              href="https://www.instagram.com/appnoz/"
              title="Instagram Noz"
              aria-label="Instagram Noz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Logo - ioasys" />
            </a>
            <a
              href="https://www.facebook.com/appnoz"
              title="Facebook Noz"
              aria-label="Facebook Noz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Logo - ioasys" />
            </a>
            <a
              href="https://www.linkedin.com/company/app-noz"
              title="Linkedin Noz"
              aria-label="Linkedin Noz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="Logo - ioasys" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;